import type ButtonProps from '@aurora/external-types/components/common/Button/ButtonProps';
import {
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/external-types/components/common/Button/enums';
import { IconSize, IconTypes } from '@aurora/external-types/components/common/Icon/enums';
import {
  LoadingVariant,
  LoadingSize
} from '@aurora/external-types/components/common/Loading/enums';
import type LoadingProps from '@aurora/external-types/components/common/Loading/LoadingProps';
import { PanelSize, PanelType } from '@aurora/external-types/components/common/Panel/enums';
import type UserAvatarProps from '@aurora/external-types/components/users/UserAvatar/UserAvatarProps';
import { TextAlignment } from '@aurora/external-types/utils/I18n/enums';
import type { AlertProps, ModalProps } from 'react-bootstrap';
import type { ModalDialogProps } from 'react-bootstrap/lib/esm/ModalDialog';
import type { ModalHeaderProps } from 'react-bootstrap/lib/esm/ModalHeader';
import type NodeLinkProps from '@aurora/external-types/components/nodes/NodeLink/NodeLinkProps';
import type UserLinkProps from '@aurora/external-types/components/users/UserLink/UserLinkProps';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/external-types/components/common/ToastAlert/enums';
import dynamic from 'next/dynamic';
import type {
  ExternalComponents,
  ExternalComponentEnums
} from '@aurora/external-types/externalContext';
import type {
  PanelProps,
  PanelSectionProps
} from '@aurora/external-types/components/common/Panel/PanelProps';

const Alert = dynamic<AlertProps>(() => import('./bootstrap/ExternalAlert'));
const Button = dynamic<ButtonProps>(() => import('../components/common/Button/ExternalButton'));
const EmptyState = dynamic<ModalProps>(
  () => import('../components/common/EmptyState/ExternalEmptyState')
);
const Modal = dynamic<ModalProps>(() => import('./bootstrap/ExternalModal'));
const ModalBody = dynamic<{}>(() => import('./bootstrap/ExternalModalBody'));
const ModalDialog = dynamic<ModalDialogProps>(() => import('./bootstrap/ExternalModalDialog'));
const ModalFooter = dynamic<{}>(() => import('./bootstrap/ExternalModalFooter'));
const ModalHeader = dynamic<ModalHeaderProps>(() => import('./bootstrap/ExternalModalHeader'));
const ModalTitle = dynamic<{}>(() => import('./bootstrap/ExternalModalTitle'));
const NodeLink = dynamic<NodeLinkProps>(
  () => import('../components/nodes/NodeLink/ExternalNodeLink')
);
const Loading = dynamic<LoadingProps>(() => import('../components/common/Loading/ExternalLoading'));
const Panel = dynamic<PanelProps>(() => import('../components/common/Panel/ExternalPanel'));
const PanelHeader = dynamic<PanelSectionProps>(() =>
  import('../components/common/Panel/ExternalPanel').then(panel => panel.PanelHeader)
);
const PanelBody = dynamic<PanelSectionProps>(() =>
  import('../components/common/Panel/ExternalPanel').then(panel => panel.PanelBody)
);
const PanelFooter = dynamic<PanelSectionProps>(() =>
  import('../components/common/Panel/ExternalPanel').then(panel => panel.PanelFooter)
);
const UserAvatar = dynamic<UserAvatarProps>(
  () => import('../components/users/UserAvatar/ExternalUserAvatar')
);
const UserLink = dynamic<UserLinkProps>(
  () => import('../components/users/UserLink/ExternalUserLink')
);

const enums: ExternalComponentEnums = {
  ButtonVariant,
  IconSize,
  IconTypes,
  LoadingSize,
  LoadingVariant,
  LoadingButtonVariant,
  PanelSize,
  PanelType,
  TextAlignment,
  ToastAlertVariant,
  ToastVariant
};

const components: ExternalComponents = {
  Alert,
  Button,
  EmptyState,
  Loading,
  Modal,
  ModalBody,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  NodeLink,
  Panel,
  PanelBody,
  PanelFooter,
  PanelHeader,
  UserAvatar,
  UserLink,
  enums
};

export default components;
