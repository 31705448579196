import { useMutation, useQuery, useSuspenseQuery } from '@apollo/client/react/hooks';
import type {
  ExternalContext,
  ExternalContextMessage,
  ExternalContextNode,
  ExternalContextUser,
  ExternalPageContext,
  ExternalUtils
} from '@aurora/external-types/externalContext';
import type { EndUserPages, EndUserQueryParams } from '@aurora/external-types/page/pages';
import type { RouterAndLink } from '@aurora/external-types/page/router';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import SwitchBranchContext from '@aurora/shared-client/components/context/SwitchBranchContext/SwitchBranchContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import ToastContext from '@aurora/shared-client/components/context/ToastContext/ToastContext';
import {
  deleteFromInternalApi,
  getFromInternalApi,
  postToInternalApi
} from '@aurora/shared-client/helpers/ApiHelper';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { QuiltComponent } from '@aurora/shared-generated/types/graphql-schema-types';
import type { I18n } from '@aurora/shared-types/texts';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import components from './components/index';

const assetUrlSeparator = '_next/static/media';

function useExternalContext(
  component: Pick<QuiltComponent, 'id'>,
  i18n: Omit<I18n<unknown, unknown>, 'loading' | 'refetch'>,
  localOverride: boolean
): ExternalContext {
  const tenant = useContext(TenantContext);
  const { branchName } = useContext(SwitchBranchContext);
  const { community, authUser, contextNode, contextMessage, contextUser } = useContext(AppContext);
  const toastContext = useContext(ToastContext);
  const endUserRoutes = useEndUserRoutes();

  const { repoName, mainBranchName } = tenant.publicConfig;
  const effectiveBranch = branchName ?? mainBranchName;

  const assetUrl = (url: string): string => {
    const separator = assetUrlSeparator;
    if (!localOverride && url.includes(separator)) {
      const [prefix, suffix] = url.split(separator);
      const imageUrl = new URL(
        [repoName, effectiveBranch, 'mf', separator, suffix.slice(1, suffix.length)].join('/'),
        prefix
      );
      return imageUrl.toString();
    }
    return url;
  };

  const utils: ExternalUtils = {
    log: getLog(component.id),
    i18n,
    useClassNameMapper: (localClassNameMap?: Record<string, string>) => {
      return useClassNameMapper(localClassNameMap) as (...classes: unknown[]) => string | undefined;
    },
    useQuery,
    useSuspenseQuery,
    useMutation,
    getFromInternalApi,
    postToInternalApi,
    deleteFromInternalApi,
    assetUrl,
    ...toastContext
  };

  const pageContext: ExternalPageContext = {
    tenant,
    endUserRoutes: endUserRoutes as RouterAndLink<EndUserPages, EndUserQueryParams>,
    community,
    authUser,
    contextNode: contextNode as unknown as ExternalContextNode,
    contextMessage: contextMessage as unknown as ExternalContextMessage,
    contextUser: contextUser as ExternalContextUser
  };

  return {
    components,
    utils,
    pageContext
  };
}

export default useExternalContext;
