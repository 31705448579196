export enum ToastAlertVariant {
  INFO = 'info',
  WARNING = 'warning',
  DANGER = 'danger',
  SUCCESS = 'success'
}

export enum ToastVariant {
  FLYOUT = 'flyout',
  BANNER = 'banner'
}
